import React, { Component } from 'react';
import textData from '../../data/about-me.json';

import GutenbergImage from '../gutenbergimage';

import Grid from '@mui/material/Grid';

class About extends Component {

    constructor(props) {
        super(props);

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        this.state = { lang: params.get("lang") };
    }

    render() {
        return (
            <>
                <Grid container sx={{ height: '100%', maxWidth: '100%', paddingLeft: '10%', paddingRight: '10%', marginBottom: '55px', paddingTop: '25px' }} direction="row" alignItems="stretch" justifyContent="flex-end">
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                    >
                        <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/Foto.jpg'} alt='About me' />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={1}
                    >
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={7}
                    >
                        <Grid container
                        >
                            <Grid container item>
                                <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/info/infopage_Wellenlinie.png'} alt='' />
                            </Grid>
                            <Grid container item>
                                <p style={{textAlign: "left", fontSize: "15px", lineHeight:"1.5" }}>{textData[this.state.lang]}</p>
                            </Grid>
                            <Grid container item>
                                <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/info/infopage_Wellenlinie.png'} alt='' />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

    }
}

export default About; 