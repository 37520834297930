import React, { Component } from 'react';

class GutenbergImage extends Component {

    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    };

    contextMenu(e) {
        e.preventDefault();
        return false;
    };

    render() {
        return <img onContextMenu={this.contextMenu} sx={{ objectFit: 'contain' }} src={this.props.src} alt={this.props.alt} onClick={this.props.onclick} className={this.props.className} />     
    }
}

export default GutenbergImage; 