import React, { Component } from 'react';

import GutenbergImage from '../gutenbergimage';

class HeaderTitle extends Component {

    redirect() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        const lang = params.get("lang");

        window.location.href = lang ? "/?lang=" + lang : "/?lang=de";
    }

    render() {
        return (
            <GutenbergImage className="pointer" src={process.env.PUBLIC_URL + '/resources/images/header/Name.png'} alt='Lucy Hollwedel' onclick={this.redirect}/>
        );
    }
}

export default HeaderTitle;