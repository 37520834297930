import React, { Component } from 'react';
import Box from '@mui/material/Box';

class Datenschutz extends Component {
    render() {
        return (
            <Box width="100%">
                <iframe title="datenschutz" width="100%"  src={process.env.PUBLIC_URL + '/resources/datenschutz.html'} />
            </Box>
        );

    }
}

export default Datenschutz;