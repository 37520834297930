import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { Component } from 'react';

import GutenbergImage from '../gutenbergimage';



import Modal from '@mui/material/Modal';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import ThumbnailGrid from '../thumbnailGrid';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: '75%',
    bgcolor: 'background.paper',
    p: 4,
};


const itemData = [
    {
        title: 'cover',
        items: [
            {
                name: 'dead_defoe.jpg',
                title: 'Dead Defoe',
            },
            {
                name: 'TBWKY.jpg',
                title: 'TBWKY',
            },
            {
                name: 'wale.jpg',
                title: 'Wale',
            }
        ]
    },
    {
        title: 'maps',
        items: [
            {
                name: 'bremen_15.jpg',
                title: 'Bremen',
            },
            {
                name: 'weltkarte.jpg',
                title: 'Weltkarte',
            },
            {
                name: 'weltkarte_leuchtend.jpg',
                title: 'Weltkarte',
            } 
        ]
    },
    {
        title: 'medical',
        items: [
            {
                name: 'face_mouth.jpg',
                title: 'Gesicht Mund',
            },
            {
                name: 'guinea_worm.jpg',
                title: 'Guniea Wurm',
            },
            {
                name: 'infectious_masqueracde.jpg',
                title: 'Masquerade',
            },
            {
                name: 'influenca_pirate.jpg',
                title: 'Arr ',
            }
        ]
    },
    {
        title: 'editorial',
        items: [
            {
                name: 'arythmia.jpg',
                title: 'Arythmia',
            },
            {
                name: 'corona_cruise.jpg',
                title: 'Corona Cruise',
            },
            {
                name: 'in_nomine_pilae.jpg',
                title: 'In Nomine Pilae',
            },
            {
                name: 'wilhelmine_druck.jpg',
                title: 'Wilhelmine ',
            }
        ]
    },
    {
        title: 'flora_fauna',
        items: [
            {
                name: 'pengwings.jpg',
                title: 'Pengwings ',
            },
            {
                name: 'black_fly.jpg',
                title: 'Black Fly',
            },
            {
                name: 'butterfisch.jpg',
                title: 'Butterfisch',
            },
            {
                title: 'Extinct',
                animation: 'extinct.gif'
            }
        ]
    },
    {
        title: 'sequences',
        items: [
            {
                name: 'chamaeleon.jpg',
                title: 'Chamaeleon',
            },
            {
                name: 'endpapers_sick-skellies.jpg',
                title: 'Skelette',
            },
            {
                name: 'malleus-maleficarum.jpg',
                title: 'Malleus Maleficarum',
            }
        ]
    }
];


class ImageGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            slide: 0
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }


    handleOpen(project) {
        this.setState({ open: true, slide: itemData.indexOf(project) });
    }

    handleClose() {
        this.setState({ open: false });
    }

    render() {
        return (
            <Box sx={{ width: '100%', height: '100%', alignItems: "center", justifyContent: "center", display: "flex" }}>
                <Grid
                    width={"75%"}
                    container
                    rowSpacing={5}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ThumbnailGrid
                        imageName="thumbnail_cover"
                        imageAlt="Cover"
                        handleOpen={() => this.handleOpen(itemData[0])}
                    />
                    <ThumbnailGrid
                        imageName="thumbnail_maps"
                        imageAlt="Maps"
                        handleOpen={() => this.handleOpen(itemData[1])}
                    />
                    <ThumbnailGrid
                        imageName="thumbnail_medical"
                        imageAlt="Medical"
                        handleOpen={() => this.handleOpen(itemData[2])}
                    />
                    <ThumbnailGrid
                        imageName="thumbnail_editorial"
                        imageAlt="Editorial"
                        handleOpen={() => this.handleOpen(itemData[3])}
                    />
                    <ThumbnailGrid
                        imageName="thumbnail_flora_fauna"
                        imageAlt="Flora and Fauna"
                        handleOpen={() => this.handleOpen(itemData[4])}
                    />
                    <ThumbnailGrid
                        imageName="thumbnail_sequences"
                        imageAlt="Sequences"
                        handleOpen={() => this.handleOpen(itemData[5])}
                    />
                </Grid>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Swiper
                            initialSlide={0}
                            navigation={true}
                            modules={[Navigation]}
                            className="imageSqiper"
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                        >
                            {
                                itemData[this.state.slide].items.map((item) => (
                                    <SwiperSlide
                                        key={item.name}
                                    >
                                        <GutenbergImage
                                            src={item.animation ? process.env.PUBLIC_URL + '/resources/projects/' + itemData[this.state.slide].title + '/' + item.animation : process.env.PUBLIC_URL + '/resources/projects/' + itemData[this.state.slide].title + '/'  + item.name + '?w=248&fit=crop&auto=format'}
                                            alt={item.title}
                                            className="slider-image"
                                        />
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </Box>
                </Modal>
            </Box>


        );
    }
}

export default ImageGallery; 