import React, { Component } from 'react';
import textData from '../../data/impressum.json';
import Datenschutz from './datenschutz';

class Impressum extends Component {

    constructor(props) {
        super(props);

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        this.state = { lang: params.get("lang") ? params.get("lang") : "de" };
    }

    render() {
        const impressum = textData.impressum[this.state.lang];
        const contact = textData.contact[this.state.lang];
        const disclaimer = textData.disclaimer[this.state.lang];

        return (
            <>
                <h2>
                    Impressum & Datenschutzerklärung
                </h2>
                <div>
                    <h3>{impressum.title}  </h3>
                    <p>{impressum.name}  </p>
                    <p>{impressum.email}  </p>
                </div>
                <div>
                    <h3>{contact.title}</h3>
                    <p>{contact.email}</p>
                </div>
                <div style={{ textAlign: "justify" }}>
                    <h3>{disclaimer.title}  </h3>
                    {disclaimer.paragraphs.map((item) => (
                        <div>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <h3>Datenschutzerklärung</h3>
                    <Datenschutz />
                </div>

            </>
        );

    }
}

export default Impressum; 