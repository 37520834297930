import  Header from './components/header';
import  Body  from './components/body';
import Footer from './components/footer';

import './App.css';

function App() {
    return (
        <div className='root'>
          <Header />
          <Body />
          <Footer />
        </div>
  );
}

export default App;
