import React, { Component } from 'react';

import ImageGallery from './body/imagegallery';
import About from './body/about';
import Impressum from './body/impressum';


import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";


class Body extends Component {

    render() {
        return (
            <div className="body">
            <BrowserRouter>
                    <Routes>
                    <Route path="/about" element={<About />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/" element={<ImageGallery/>} />
                    </Routes>
                </BrowserRouter>
            </div>
            );
    }
}

export default Body; 