import Grid from '@mui/material/Grid';
import { Component, default as React } from 'react';
import GutenbergImage from './gutenbergimage';

class ThumbnailGrid extends Component {
    constructor(props) {
        super(props);
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        this.state = {
            lang: params.get("lang") ? params.get("lang") : "de"
        };
    }

    render() {
        return (
            <Grid
                container
                item
                xs={12}
                md={4}
                sx={{ height: '225px' }}
                onClick={this.props.handleOpen}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <GutenbergImage
                    src={process.env.PUBLIC_URL + '/resources/images/startseite/' + this.props.imageName + '_' + this.state.lang + '.png?fit=crop&auto=format'}
                    alt={this.props.imageAlt}
                    className="pointer"
                />
            </Grid>
        );
    }
}

export default ThumbnailGrid; 