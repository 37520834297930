import { Box } from '@mui/material';
import React, { Component } from 'react';
import GutenbergImage from './gutenbergimage';

class Footer extends Component {
    constructor(props) {
        super(props);
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        this.state = {
            lang: params.get("lang") ? params.get("lang") : "de"
        };
    }

    render() {
        return <div className="footer">
            <Box height={"100%"}>
                <a href={this.state.lang === 'en' ? '/impressum?lang=en' : '/impressum?lang=de'} >
                    <GutenbergImage
                        src={process.env.PUBLIC_URL + '/resources/images/footer/Impressum_Datenschutz.png?fit=crop&auto=format'}
                        alt="Impressum"
                    />
                </a>

            </Box>

        </div>;

    }
}

export default Footer;