import React, { Component } from 'react';
import GutenbergImage from '../gutenbergimage';

class FartingLady extends Component {

    constructor(props) {
        super(props);

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        this.state = { fartingState: 0, lang: params.get("lang") };
        this.fart = this.fart.bind(this);
     
    }

    fart() {
        this.setState({ fartingState: this.state.fartingState === 5 ? 0 : this.state.fartingState + 1 });

        if (this.state.fartingState === 5) {
            const el = document.getElementById('root');

            var children5 = el.children;
            for (var i5 = 0; i5 < children5.length; i5++) {
                var tableChild5 = children5[i5];
                tableChild5.classList.add("fartinaction");
            }
        } else if (this.state.fartingState === 1) {
            const el = document.getElementById('root');

            var children1 = el.children;
            for (var i1 = 0; i1 < children1.length; i1++) {
                var tableChild1 = children1[i1];
                tableChild1.classList.remove("fartinaction");
            }
        }
    }

    render() {
        return (
            <div className='fartinglady'>
                <div className='icons' >
                    <a className='language' href={this.state.lang === 'en' ? '?lang=de' : '?lang=en'}>
                        <GutenbergImage src={this.state.lang === 'en' ? process.env.PUBLIC_URL + '/resources/images/header/Deutsch.png' : process.env.PUBLIC_URL + '/resources/images/header/Englisch.png'} />
                    </a>
                    <a className='about' href={this.state.lang === 'en' ? '/about?lang=en' : '/about?lang=de'}>
                        <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/header/Info.png'} alt='About me' />
                    </a>
                    <a className='mail' href="mailto:cyluho@outlook.de">
                        <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/header/Mail_Symbol.png'} />
                    </a>
                    <a className='insta' href="https://www.etsy.com/de-en/shop/CyluhoPrints" target="_blank" rel="noreferrer">
                        <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/header/shop.png'} />
                    </a>
                </div>
                <GutenbergImage src={process.env.PUBLIC_URL + '/resources/images/header/Farting_lady_growing_' + this.state.fartingState + '.png'} alt='Lucy Hollwedel' onclick={this.fart} />
            </div>
         );
    }
}

export default FartingLady; 