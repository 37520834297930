import React, { Component } from 'react';
import '../styles/header.css';
import Grid from '@mui/material/Grid';
import FartingLady from './header/fartinglady';
import HeaderTitle from './header/headertitle';

class Header extends Component {

    render() {
        return (
            <Grid className="header" container sx={{ maxHeight: '350px', maxWidth: '100%' }} direction="row" alignItems="stretch" justifyContent="flex-end">
                <Grid 
                    container
                    item
                    xs={12}
                    md={6}
                    sx={{ height: '150px' }}
                    style={{  marginTop: "auto"}}
                >
                        <HeaderTitle />
                    </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    sx={{ maxHeight: '200px' }}
                >
                        <FartingLady />
                </Grid>
            </Grid>
        );
    }
}

export default Header; // Don�t forget to use export default!